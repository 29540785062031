<template>
  <button class="next-button-layout">
    {{ $t('global.navigation.next') }}
    <img :src="require('@/assets/icons/right-arrow.svg')" :alt="$t('global.imageAlts.rightArrow')">
  </button>
</template>

<script>
export default {
  name: 'NextButtonLayout'
}
</script>

<style lang="scss" scoped>
.next-button-layout {
  display: flex;
  align-items: center;
  font-weight: $fw-l;
  color: $c-secondary;
  font-size: $fz-s;
  img {
    margin-left: $space-s;
  }
}
</style>
