<template>
  <button class="prev-button-layout">
    <img :src="require('@/assets/icons/left-arrow.svg')" :alt="$t('global.imageAlts.leftArrow')">
    {{ $t('global.navigation.prev') }}
  </button>
</template>

<script>
export default {
  name: 'PrevButtonLayout'
}
</script>

<style lang="scss" scoped>
.prev-button-layout {
  display: flex;
  align-items: center;
  font-weight: $fw-l;
  color: $c-secondary;
  font-size: $fz-s;
  img {
    margin-right: $space-s;
  }
}
</style>
