<template>
  <step-lesson-layout :title-annotation="$t('module3.part3.lesson2.titleAnnotation')">
    <template v-slot:content>

      <!-- BLOCK 1 -->
      <div class="app-text-block">
        <p class="title">{{ $t('module3.part3.lesson2.block1.title') }}</p>

        <div class="lesson-block">
          <div class="step-wrapper">
            <div class="illu-wrapper">
              <div class="illu" :class="{ [currentStep.name]: true }" v-html="require('@/assets/module3/part3/devis-conseils.svg?inline')"></div>
            </div>
            <transition name="fade" mode="out-in">
              <div :key="currentStep.name" class="content-wrapper">
                <p class="label">{{ currentStep.label }}</p>
                <p class="paragraphe" v-html="currentStep.content"></p>
              </div>
            </transition>
          </div>
          <div class="pagination">
            <div class="prev-nav-button">
              <prev-button-layout @click="prevDevisStep" v-show="stepIndex > 0"></prev-button-layout>
            </div>
            <p class="page-index">
              {{ stepIndex + 1 }} / {{ steps.length }}
            </p>
            <div class="next-nav-button">
              <next-button-layout @click="nextDevisStep" v-show="!isLastStep"></next-button-layout>
            </div>
          </div>
        </div>
      </div>

      <!-- END BLOCK -->
      <div class="buttons-wrapper">
        <div class="app-decoration-block end-block">
          <p class="decoration-block-content">
            {{ $t('module3.part3.lesson2.endBlock') }}
          </p>
          <app-button-layout @click="goToNextStep">
            {{ $t('global.navigation.resume') }}
          </app-button-layout>
          <app-button-layout color="alternate-secondary" @click="resumeLater">
            {{ $t('global.navigation.resumeLater') }}
          </app-button-layout>
        </div>
      </div>
    </template>
  </step-lesson-layout>
</template>

<script>
import StepLessonLayout from '@/components/training/StepLessonLayout'
import useTrainingUtils from '@/utils/useTrainingUtils'
import AppButtonLayout from '@/components/buttons/AppButtonLayout'
import PrevButtonLayout from '@/components/buttons/PrevButtonLayout'
import NextButtonLayout from '@/components/buttons/NextButtonLayout'

export default {
  name: 'Module3Part3Lesson2',
  components: { StepLessonLayout, AppButtonLayout, PrevButtonLayout, NextButtonLayout },
  setup (props, context) {
    const { goToNextStep, resumeLater } = useTrainingUtils()
    return { goToNextStep, resumeLater }
  },
  data () {
    return {
      steps: [
        {
          name: 'step1',
          label: this.$t('module3.part3.lesson2.block1.steps.step1.label'),
          content: this.$t('module3.part3.lesson2.block1.steps.step1.content')
        },
        {
          name: 'step2',
          label: this.$t('module3.part3.lesson2.block1.steps.step2.label'),
          content: this.$t('module3.part3.lesson2.block1.steps.step2.content')
        },
        {
          name: 'step3',
          label: this.$t('module3.part3.lesson2.block1.steps.step3.label'),
          content: this.$t('module3.part3.lesson2.block1.steps.step3.content')
        },
        {
          name: 'step4',
          label: this.$t('module3.part3.lesson2.block1.steps.step4.label'),
          content: this.$t('module3.part3.lesson2.block1.steps.step4.content')
        }
      ],
      stepIndex: 0
    }
  },
  computed: {
    isLastStep () {
      return this.stepIndex >= this.steps.length - 1
    },
    currentStep () {
      return this.steps[this.stepIndex]
    }
  },
  methods: {
    nextDevisStep () {
      this.stepIndex++
    },
    prevDevisStep () {
      this.stepIndex--
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../styles/module3Lesson';

.app-text-block {
  margin: 0;
  .title {
    padding: 0 $space-sm;
  }
}

.lesson-block {
  max-width: $bp-phone;
  margin: 0 auto;
  .step-wrapper {
    display: flex;
    align-items: center;
  }
  .content-wrapper {
    .label {
      color: $c-secondary;
      font-weight: $fw-l;
      text-transform: uppercase;
      word-break: break-word;
    }
  }
  .illu-wrapper {
    margin-right: $space-sm;
    flex-shrink: 0;
    .illu {
      max-width: 100%;
    }
  }
}
</style>

<style lang="scss">

.lesson-block {
  .illu-wrapper {
    .illu svg {
      max-width: 100%;
      g[data-status] {
        transition: opacity 0.8s ease-out;
      }
      g[data-status='on'] {
        opacity: 0;
      }
    }
    .illu.step1 {
      g[data-index='1'][data-status='on'] {
        opacity: 1;
      }
    }
    .illu.step2 {
      g[data-index='2'][data-status='on'] {
        opacity: 1;
      }
    }
    .illu.step3 {
      g[data-index='3'][data-status='on'] {
        opacity: 1;
      }
    }
    .illu.step4 {
      g[data-index='4'][data-status='on'] {
        opacity: 1;
      }
    }
  }
}
</style>
